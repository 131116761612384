export const environment = {
  production: true,
  baseURL: 'https://api.jvgroup.co.in/index.php/',
  mediaURL: 'https://api.jvgroup.co.in/uploads/',
  onesignal: {
    appId: '',
    googleProjectNumber: '',
    restKey: ''
  },
  general: {
    symbol: '$',
    code: 'USD'
  },
  authToken: 'aZVYsecret_OfAtleast32CharactRFC',
  default_country_code: '91'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
